import Plugin from 'lib/modulor/plugin'

export class ButtonToOctopusMenu extends Plugin
  @defaults =
    debug: false
    name: 'OctopusModule__ButtonTo__OctopusMenu'

  @selector: ".octopus_module__button_to__octopus_menu"

  on_init: ->
    @$element.on "click.#{@options.name}", (e) =>
      @toggle_menu()

  toggle_menu: ->
    $("body")[0].toggleAttribute("data-octopus-menu")

ButtonToOctopusMenu.register()
