# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class OctopusPressArticleBio extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'OctopusPressArticleBioModule'
#
#   @selector: ".octopus_press_article_bio_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# OctopusPressArticleBio.register()
