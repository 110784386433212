import { Controller } from "@hotwired/stimulus"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller
  @targets: ["slide"]

  connect: ->
    @onStartListener = @onStart.bind(@)
    @element.addEventListener "screen:start", @onStartListener

    @onSlideCompletedListener = @onSlideCompleted.bind(@)
    @element.addEventListener "slide:completed", @onSlideCompletedListener

    @onResetListener = @onReset.bind(@)
    @element.addEventListener "screen:reset", @onResetListener

    @currentSlideIndex = 0
    @currentSlideTarget.setAttribute 'current', ""
    @nextSlideTarget.setAttribute 'next', ""

    # wait until the child controllers (slide, …) are connected
    setTimeout (=>
      @start()
    ), 1000

  disconnect: ->
    @reset()

  start: (detail = {}) ->
    # console.log "\"#{@identifier}\" starting"
    @isCompleted = false
    @currentSlideTarget.dispatchEvent @startSlideEvent

  onStart: (event) ->
    event.stopImmediatePropagation()
    @start(event.detail)


  completed: ->
    @element.dispatchEvent(@completedEvent)
    @isCompleted = true
    # console.log "\"#{@identifier}\" completed"

  onCompleted: (event) ->
    # prevent this event from bubbling up
    return if event.currentTarget.isEqualNode(@element)
    return if @isCompleted

  onReset: (event) ->
    event.stopImmediatePropagation()
    @reset()

  onSlideCompleted: (event) ->
    event.stopImmediatePropagation()
    @previousSlideTarget.dispatchEvent @resetSlideEvent
    @startNextSlide()


  startNextSlide: ->
    if @currentSlideIndex >= @maxSlideIndex
      @currentSlideIndex = 0
    else
      @currentSlideIndex += 1

    @resetAttributes()
    @currentSlideTarget.setAttribute "current", ""
    @nextSlideTarget.setAttribute "next", ""

    @currentSlideTarget.dispatchEvent @startSlideEvent

  resetAttributes: ->
    @slideTargets.forEach (slide) -> slide.removeAttribute 'current'
    @slideTargets.forEach (slide) -> slide.removeAttribute 'next'

  @property "maxSlideIndex",
    get: -> @slideTargets.length - 1

  @property "currentSlideTarget",
    get: -> @slideTargets[@currentSlideIndex]

  @property "previousSlideTarget",
    get: ->
      previousSlideIndex = @currentSlideIndex - 1
      if previousSlideIndex < 0 then previousSlideIndex = @maxSlideIndex
      @slideTargets[previousSlideIndex]

  @property "nextSlideTarget",
    get: ->
      nextSlideIndex = @currentSlideIndex + 1
      if nextSlideIndex > @maxSlideIndex then nextSlideIndex = 0
      @slideTargets[nextSlideIndex]

  @property "startSlideEvent",
    get: -> new CustomEvent "slide:start"

  @property "completedEvent",
    get: -> new CustomEvent "screen:completed", bubbles: true

  @property "resetSlideEvent",
    get: -> new CustomEvent "slide:reset"

  reset: ->
