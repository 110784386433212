import _debounce from 'lodash/debounce'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'SiteHeaderModule__Content'
    debounce: 100

  @selector: ".site_header_module__content"

  on_init: ->
    @$element.on "click.#{@options.name}", ".site_header_module__button_to_menu", (e) =>
      e.preventDefault()
      if menu_module_content = document.querySelector(".menu_module__content")
        menu_module_content.toggleAttribute("aria-expanded", true)

    @$element.on "click.#{@options.name}", ".site_header_module__button_to_search", (e) =>
      e.preventDefault()
      if quick_search_module_content = document.querySelector(".quick_search_module__content")
        quick_search_module_content.toggleAttribute("aria-expanded", true)

Content.register()
