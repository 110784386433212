import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'ProjectSectionModule__Content'

  @selector: ".project_section_module__content"

  on_init: ->
    @$element.on "click.#{@options.name}", '.project_section_module__button', (e) =>
      @toggle_description()

  get_description: -> @$element.find '.project_section_module__description'

  is_expanded: -> @element.hasAttribute "data-expanded"

  toggle_description: ->
    if @is_expanded()
      @get_description().css 'height', '0px'
      @element.toggleAttribute "data-expanded", false
    else
      @get_description().css 'height', @get_description()[0].scrollHeight+"px"
      @element.toggleAttribute "data-expanded", true

Content.register()
