import Plugin from 'lib/modulor/plugin'

export default class PreviewAttachments extends Plugin
  on_init: ->
    @inject_counter()

  on_destroy: ->
    @remove_counter()

  get_counter: -> @$element.find('.identity_assets_module__preview_attachments__counter')
  get_counter_index: -> @get_counter().find('.identity_assets_module__preview_attachments__counter__index')
  get_counter_total: -> @get_counter().find('.identity_assets_module__preview_attachments__counter__total')

  inject_counter: ->
    @$element.append("""
      <div class='identity_assets_module__preview_attachments__counter'>
        <span class='identity_assets_module__preview_attachments__counter__index'></span>/<span class='identity_assets_module__preview_attachments__counter__total'></span>
      </div>
    """)

  remove_counter: ->
    @get_counter().remove()
