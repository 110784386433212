import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

class Items extends Plugin
  @defaults =
    debug: false
    name: 'HomepageImagesModule__Items'

  @selector: '.homepage_images_module__items'

  @property "autoPlay",
    get: ->
      return false unless @element.hasAttribute("data-auto-play")
      10000

  on_init: ->
    return unless @items().length > 1

    { default: Flickity } = await `import('flickity' /* webpackChunkName: "flickity" */)`

    @flickity = new Flickity(@element,
      autoPlay: @autoPlay,
      adaptiveHeight: true
      cellAlign: 'left'
      cellSelector: ".homepage_images_module__item"
      initialIndex: 0
      pageDots: false
      prevNextButtons: false
      wrapAround: true
    )

    @$element.on "prevSlide.#{@options.name}", (e) =>
      @flickity.previous() if @flickity

    @$element.on "nextSlide.#{@options.name}", (e) =>
      @flickity.next() if @flickity

    @$element.css('cursor', 'pointer')

  on_destroy: ->
    @flickity.destroy() if @flickity
    @$element.off '.flickity'
    @$element.css('cursor', null)

  on_resize: ->
    @flickity.resize() if @flickity

  items: -> @$element.find(".homepage_images_module__item")

Items.register()
