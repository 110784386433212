import Plugin from 'lib/modulor/plugin'

class AttachmentImages extends Plugin
  @defaults =
    debug: false
    name: 'ImagesModule__AttachmentImages'

  @selector: '.images_module__attachment_images'

  on_init: ->
    return unless @get_attachments().length > 1

    { default: Flickity } = await `import('flickity' /* webpackChunkName: "flickity" */)`

    @flickity = new Flickity(@element,
      adaptiveHeight: true
      cellAlign: 'left'
      cellSelector: ".images_module__attachment_image"
      initialIndex: 0
      pageDots: false
      prevNextButtons: false
      wrapAround: true
    )

    @$element.on "staticClick.flickity", (e) =>
      @flickity.next() if @flickity

    @$element.on "prevSlide.#{@options.name}", (e) =>
      @flickity.previous() if @flickity

    @$element.on "nextSlide.#{@options.name}", (e) =>
      @flickity.next() if @flickity

    @$element.css('cursor', 'pointer')

  on_destroy: ->
    @flickity.destroy() if @flickity
    @$element.off '.flickity'
    @$element.css('cursor', null)

  on_resize: ->
    @flickity.resize() if @flickity

  get_attachments: -> @$element.find(".images_module__attachment_image")

AttachmentImages.register()
