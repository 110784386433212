# import _debounce from 'lodash/debounce'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'MenuModule__Content'
    debounce: 100

  @selector: ".menu_module__content"

  @property "site_header_module_menu_button",
    get: -> document.querySelector(".site_header_module__button_to_menu")

  @property "button_to_menu",
    get: -> @element.querySelector(".menu_module__button_to_menu")

  on_init: ->
    @$element.on "click.#{@options.name}", ".menu_module__bar", (e) =>
      e.preventDefault()
      @element.toggleAttribute "aria-expanded"

    @$element.on "click.#{@options.name}", ".menu_module__button_to_menu", (e) =>
      e.preventDefault()
      @element.setAttribute "aria-expanded", ""

    @$element.on "click.#{@options.name}", ".menu_module__button_to_close", (e) =>
      e.preventDefault()
      @element.removeAttribute "aria-expanded"

    @keyup_handler = (e) => @element.removeAttribute "aria-expanded" if e.key == "Escape"
    document.addEventListener "keyup", @keyup_handler

    if @button_to_menu && @site_header_module_menu_button
      setTimeout (=>
        options = { rootMargin: "0px", threshold: 0 }
        @observer = new IntersectionObserver(@on_observer.bind(@), options)
        @observer.observe(@site_header_module_menu_button)
      ), 500

  on_destroy: ->
    @observer.disconnect() if @observer
    document.removeEventListener "keyup", @keyup_handler if @keyup_handler
    @element.removeAttribute "aria-expanded"

  on_observer: (changes, observer) ->
    for change in changes
      if change.intersectionRatio > 0
        unless @button_to_menu.hasAttribute("hidden")
          animType = "out"
          @button_to_menu.setAttribute("data-anim", animType)
          @$element.one "animationend", ".menu_module__button_to_menu rect:nth-child(30)", (e) =>
            @button_to_menu.removeAttribute("data-anim")
            @button_to_menu.setAttribute("hidden", true)
      else
        animType = "in"
        @button_to_menu.toggleAttribute("hidden", false)
        @button_to_menu.setAttribute("data-anim", animType)
        @$element.one "animationend", ".menu_module__button_to_menu rect:nth-child(30)", (e) =>
          @button_to_menu.removeAttribute("data-anim")

Content.register()
