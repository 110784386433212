import { Controller } from "@hotwired/stimulus"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller
  @values:
    sequence: { type: Array, default: [] }

  connect: ->
    @onStartListener = @onStart.bind(@)
    @element.addEventListener("slide:start", @onStartListener)

    @onGraphicCompletedListener = @onGraphicCompleted.bind(@)
    @element.addEventListener("graphic:completed", @onGraphicCompletedListener)

    @onResetListener = @onReset.bind(@)
    @element.addEventListener("slide:reset", @onResetListener)

  disconnect: ->
    @reset()

  start: (detail = {}) ->
    # console.log "\"#{@identifier}\" starting"
    @isCompleted = false

    # console.log("---------------------------")

    @currentTargetIndex = 0
    setTimeout (=>
      @currentTarget.dispatchEvent(@getStartGraphicEvent(@currentTargetDetail))
    ), @currentTargetDelay

  onStart: (event) ->
    event.stopImmediatePropagation()
    @start(event.detail)

  completed: ->
    @element.dispatchEvent(@completedEvent)
    @isCompleted = true
    # console.log "\"#{@identifier}\" completed"

  onGraphicCompleted: (event) ->
    return if @isCompleted

    if @currentTargetIndex + 1 > @maxTargetIndex
      @completed()
    else
      setTimeout =>
        @startNextTarget()
      , @nextTargetDelay

  onReset: (event) ->
    event.stopImmediatePropagation()
    @reset()

  reset: ->
    @targets.forEach (el) => el.dispatchEvent(@resetGraphicEvent)

  startNextTarget: ->
    @currentTargetIndex += 1
    @currentTarget.dispatchEvent(@getStartGraphicEvent(@currentTargetDetail))

  @property "maxTargetIndex",
    get: -> @sequenceValue.length - 1

  @property "currentTargetName",
    get: -> @sequenceValue[@currentTargetIndex]["name"]

  @property "currentTargetDelay",
    get: ->
      @sequenceValue[@currentTargetIndex]["delay"]

  @property "currentTargetDetail",
    get: -> @sequenceValue[@currentTargetIndex]["detail"]

  @property "nextTargetDelay",
    get: -> @sequenceValue[@currentTargetIndex + 1]["delay"]

  @property "currentTarget",
    get: -> @element.querySelector("[data-#{@identifier}-target='#{@currentTargetName}']")

  @property "targets",
    get: -> @element.querySelectorAll("[data-#{@identifier}-target]")

  @property "completedEvent",
    get: -> new CustomEvent("slide:completed", { bubbles: true })

  @property "resetGraphicEvent",
    get: -> new CustomEvent("graphic:reset")

  getStartGraphicEvent: (detail = {}) ->
    new CustomEvent("graphic:start", { detail: detail })
