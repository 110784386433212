import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'ImageModule__Content'

  @selector: ".image_module__content"

  @property "windowAspectRatio",
    get: -> window.innerWidth / window.innerHeight

  @property "aspectRatio",
    get: -> @$element.data("aspect-ratio")

  on_init: ->
    @set_orientation_in_respect_to_window_ratio()

  on_resize: ->
    @set_orientation_in_respect_to_window_ratio()

  set_orientation_in_respect_to_window_ratio: ->
    if @windowAspectRatio > @aspectRatio
      @$element.attr("data-orientation", "landscape")
    else
      @$element.attr("data-orientation", "portrait")

Content.register()
