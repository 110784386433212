import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class SignatureHtml extends WebModule__Content
  @defaults =
    debug: false
    name: 'EmailSignatureModule__SignatureHtml'

  @selector: ".email_signature_module__signature_html"

  on_init: ->
    ClipboardJS = await `import('clipboard' /* webpackChunkName: "clipboard" */)`

    @clipboard = new ClipboardJS(
      ".email_signature_module__copy_to_clipboard",
      target: => @get_result_container()[0]
    )

  get_result_container: -> @$element.find(".email_signature_module__signature_html__result")

SignatureHtml.register()
