# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class OctopusPressArticleHeader extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'OctopusPressArticleHeaderModule'
#
#   @selector: ".octopus_press_article_header_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# OctopusPressArticleHeader.register()
