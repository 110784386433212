import Page from 'models/modulor/page/page'

export class Thumbnail extends Page
  @defaults =
    debug: false
    name: 'NormaPage__Thumbnail'
    selectors: [
      'a'
    ]

  @selector: ".norma_page__thumbnail"

  on_init: ->
    for selector in @options.selectors
      @$element.find(selector).each (i, el) =>
        $(el).attr('data-content', $(el).text())

Thumbnail.register()
