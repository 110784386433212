import _debounce from 'lodash/debounce'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'SpreadsheetModule__Content'
    debounce: 100

  @selector: ".spreadsheet_module__content"

  @property "search_input",
    get: -> @element.querySelector(".spreadsheet_module__search__input")

  @property "cards",
    get: -> @element.querySelector(".spreadsheet_module__cards")

  @property "cards_path",
    get: -> @cards.getAttribute("data-path")
    set: (val) -> @cards.setAttribute("data-path", val)

  on_init: ->
    @$element.on "click.#{@options.name}", ".spreadsheet__link_to", (e) =>
      e.preventDefault()
      @cards_path = e.target.getAttribute("href")

    @$element.on "keyup.#{@options.name}", ".spreadsheet_module__search__input", _debounce ((e) =>
      @update_cards_path({ query: @search_input.value })
    ), @options.debounce

  update_cards_path: (params) ->
    searchParams = new URLSearchParams()
    if @cards_path.includes("?")
      searchParams = new URLSearchParams(@cards_path.split("?")[1])
    for k, v of params
      searchParams.set(k, v)
    @cards_path = "#{@cards_path.split("?")[0]}?#{searchParams.toString()}"

Content.register()
