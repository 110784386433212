import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'ExpandableTextModule__Content'
    expanded_class_name: 'expandable_text_module__content__is_expanded'

  @selector: ".expandable_text_module__content"

  on_init: ->
    @$element.on "click.#{@options.name}", '.expandable_text_module__title', (e) =>
      @toggle_body()

    @get_title().css 'cursor', 'pointer'

  on_destroy: ->
    @$element.removeClass @options.expanded_class_name
    @get_body().css 'height', '0px'
    @get_title().css 'cursor', 'initial'

  get_title: -> @$element.find '.expandable_text_module__title'
  get_body: -> @$element.find '.expandable_text_module__body'

  is_expanded: -> @$element.hasClass @options.expanded_class_name

  toggle_body: ->
    if @is_expanded()
      @get_body().css 'height', '0px'
      @$element.removeClass @options.expanded_class_name
    else
      @get_body().css 'height', @get_body()[0].scrollHeight+"px"
      @$element.addClass @options.expanded_class_name

Content.register()
