# import { default as WebModule__Header } from 'models/modulor/web_module/header'
#
# export class Content extends WebModule__Header
#   @defaults =
#     debug: false
#     name: 'NormaHeaderModule__Content'
#
#   @selector: ".norma_header_module__content"
#
#   on_init: ->
#
# Content.register()
