import _debounce from 'lodash/debounce'
import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

Array::sum = (fn = (x) -> x) ->
  @reduce ((a, b) -> a + fn b), 0

export class Thumbnail extends Plugin
  @defaults =
    debug: false
    name: 'StructureHeaderModule__Title'

  @selector: ".structure_header_module__title"

  @property "widthOfAllChars",
    get: ->
      minOffsetLeft = Math.min @$chars.toArray().map((el) -> el.offsetLeft)...
      maxOffsetRight = Math.max @$chars.toArray().map((el) -> el.offsetLeft + el.offsetWidth)...
      maxOffsetRight - minOffsetLeft

  @property "$chars",
    get: -> @$element.find(".structure_header_module__char")

  @property "charsFitContainer",
    get: -> @widthOfAllChars <= @containerWidth

  @property "containerWidth",
    get: -> @$element.innerWidth()

  @property "overflowWidth",
    get: -> Math.abs(@widthOfAllChars - @containerWidth)

  @property "titleScrollX",
    get: ->
      return 0 if @charsFitContainer
      parseFloat(@overflowWidth) / parseFloat(window.document.documentElement.offsetHeight - window.document.documentElement.clientHeight) * parseFloat(document.documentElement.scrollTop)

  on_init: ->
    @scroll_handler = _debounce(@on_scroll.bind(this), 10)
    $(window).on "scroll.#{@options.eventNamespace}", @scroll_handler

  on_destroy: ->
    $(window).off('scroll', @scroll_handler) if @scroll_handler

  on_resize: -> @on_scroll()

  on_scroll: ->
    @$element.css "transform", "translateX(-#{@titleScrollX}px)"

Thumbnail.register()
