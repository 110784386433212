import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class DropdownTags extends Plugin
  @defaults =
    debug: false
    name: 'EventListHeaderModule__DropdownTags'

  @selector: ".event_list_header_module__dropdown_tags"

  @property "expanded",
    get: -> @element.getAttribute("aria-expanded")
    set: (val) -> @element.setAttribute("aria-expanded", val)

  on_init: ->
    @$element.on "click.#{@options.name}", ".event_list_header_module__current_tag", (e) =>
      e.preventDefault()

      if @expanded == "true"
        @expanded = "false"
      else
        @expanded = "true"



DropdownTags.register()
