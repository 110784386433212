import { default as WebModule__Container } from 'models/modulor/web_module/container'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class Content extends WebModule__Container
  @defaults =
    debug: false
    name: 'SoftTabsContainerModule__Content'
    inactive_tab_class_name: "soft_tabs_container_module__tab__inactive"
    inactive_tab_content_class_name: "soft_tabs_container_module__tab_content__inactive"
    tab_class_name: "soft_tabs_container_module__tab"
    tab_content_class_name: "soft_tabs_container_module__tab_content"

  @selector: ".soft_tabs_container_module__content"

  @property "$tabs",
    get: -> @$element.find(".#{@options.tab_class_name}")

  @property "$current_tab",
    get: -> @$tabs.not(".#{@options.inactive_tab_class_name}").first()

  @property "current_tab_id",
    get: -> @$current_tab.data('tab-id')

  @property "$tab_contents",
    get: -> @$element.find(".#{@options.tab_content_class_name}")

  @property "tab_ids",
    get: -> @$tabs.map((i, el) -> $(el).data('tab-id'))

  on_init: ->
    @update_tab_from_url()

    @$element.on "click.#{@options.name}", ".#{@options.tab_class_name}", (e) =>
      e.preventDefault()
      tab_id = $(e.currentTarget).data('tab-id')
      @set_current_tab(tab_id)

    @pop_state_handler = (event) => @on_pop_state(event)
    window.addEventListener "popstate", @pop_state_handler

  on_destroy: ->
    window.removeEventListener("popstate", @pop_state_handler) if @pop_state_handler

  set_current_tab: (tab_id) ->
    tab_id_selector = "[data-tab-id='#{tab_id}']"
    @$tabs.addClass(@options.inactive_tab_class_name)
    @$tabs.filter(tab_id_selector).removeClass(@options.inactive_tab_class_name)
    @$tab_contents.addClass(@options.inactive_tab_content_class_name)
    @$tab_contents.filter(tab_id_selector).removeClass(@options.inactive_tab_content_class_name)

    unless location.hash == tab_id
      history.pushState({ tab_id: tab_id }, null, "#{location.origin}#{location.pathname}##{tab_id}");
    
    setTimeout (->
      window.dispatchEvent(new Event('resize'));
      lazySizes.autoSizer.checkElems() if lazySizes
    ), 250

  on_pop_state: (event) ->
    return unless event.state
    return unless tab_id = event.state.tab_id
    @set_current_tab(tab_id) unless tab_id == @current_tab_id

  update_tab_from_url: ->
    tab_id = window.location.hash[1..-1]
    unless tab_id in @tab_ids
      tab_id = @tab_ids[0]
    @set_current_tab(tab_id)

Content.register()
