import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class CurrentPast extends Plugin
  @defaults =
    name: 'EventListModule__ViewOption__CurrentPast'

  @selector: ".event_list_module__section__current_past"

  on_init: ->
    @$element.on "click.#{@options.name}", ".event_list_module__section__tab__current a", (e) =>
      e.preventDefault()
      @set_current_tab("current")

    @$element.on "click.#{@options.name}", ".event_list_module__section__tab__past a", (e) =>
      e.preventDefault()
      @set_current_tab("past")

  set_current_tab: (val) ->
    @$element.attr("data-current-tab", val);

CurrentPast.register()
