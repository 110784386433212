import { default as WebModule } from 'models/modulor/web_module/web_module'

export class Content extends WebModule
  @defaults =
    debug: false
    name: 'SearchModule__NavLink'

  @selector: ".search_module__nav__link"

  on_init: ->
    @$element.on "click", (e) =>
      e.preventDefault()
      element = document.getElementById(e.currentTarget.href.split("#").pop())
      if element instanceof HTMLElement
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})

Content.register()
