import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Screen extends WebModule__Content
  @defaults =
    debug: false
    name: 'ScreenModule'

  @selector: ".screen_module"

  @property "refreshPath",
    get: -> @element.getAttribute('data-refresh-path')

  @property "refreshInterval",
    get: -> @element.getAttribute('data-refresh-interval')

  @property "pageUpdatedAt",
    get: -> @element.getAttribute('data-page-updated-at')

  @property "screenModuleUpdatedAt",
    get: -> @element.getAttribute('data-screen-module-updated-at')

  on_init: ->
    return unless @refreshPath
    return unless @refreshInterval
    return unless @pageUpdatedAt
    return unless @screenModuleUpdatedAt
    @interval = setInterval @refresh.bind(@), @refreshInterval

  on_destroy: ->
    clearInterval(@interval) if @interval

  refresh: ->
    $.get @refreshPath, ({ page_updated_at, screen_module_updated_at }) =>
      if page_updated_at != @pageUpdatedAt || screen_module_updated_at != @screenModuleUpdatedAt
        console.log "refresh!!!"
        window.location.reload()

Screen.register()
