import PreviewAttachments from '../__preview_attachments'

export class Gallery extends PreviewAttachments
  @defaults =
    debug: false
    name: 'IdentityAssetsModule__Gallery'

  @selector: ".identity_assets_module__preview_attachments[data-preview-option='gallery']"

  on_init: ->
    super()

    { default: Flickity } = await `import('plugins/flickity' /* webpackChunkName: "flickity" */)`

    @$element.on 'staticClick.flickity', (e) => @flickity.next(true) if @flickity
    @$element.on 'change.flickity', (e) => @update_counter()

    @flickity = new Flickity(@element,
      adaptiveHeight: true
      cellSelector: '.identity_assets_module__preview_attachment'
      draggable: false
      pageDots: false
      prevNextButtons: false
    )

    @update_counter()

  on_destroy: ->
    super()
    @flickity.destroy() if @flickity
    @$element.off '.flickity'

  on_load: -> @resize_flickity()
  on_ready: -> @resize_flickity()
  on_turbolinks_render: -> @resize_flickity()

  update_counter: ->
    return unless @flickity

    index = @flickity.selectedIndex + 1
    total = @flickity.cells.length

    $index = $("<span>#{index}</span>")
    $total = $("<span>#{total}</span>")

    @get_counter_index().attr('data-index', index).html($index)
    @get_counter_total().attr('data-total', total).html($total)

  resize_flickity: ->
    @flickity.resize() if @flickity

Gallery.register()
