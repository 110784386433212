import Plugin from 'lib/modulor/plugin'

export default class Counter extends Plugin
  @defaults =
    debug: false
    name: 'HomepageImagesModule__Counter'

  @selector: ".homepage_images_module__content"

  on_init: ->
    @$element.on "change.flickity", (e, index) =>
      @get_current_slide().text(index + 1)

    @$element.on "click.#{@options.name}", ".homepage_images_module__counter__arrow_prev", (e) =>
      @get_items().trigger("prevSlide")

    @$element.on "click.#{@options.name}", ".homepage_images_module__counter__arrow_next", (e) =>
      @get_items().trigger("nextSlide")

    @get_counter().css("cursor", "pointer")

  on_destroy: ->
    @get_counter().css("cursor", null)
    @$element.off ".flickity"

  get_counter: -> @$element.find(".homepage_images_module__counter")
  get_current_slide: -> @$element.find(".homepage_images_module__counter__current_slide")
  get_items: -> @$element.find(".homepage_images_module__items")

Counter.register()
