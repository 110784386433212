# import _debounce from 'lodash/debounce'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'QuickSearchModule__Content'
    debounce: 100

  @selector: ".quick_search_module__content"

  @property "site_header_module_search_button",
    get: -> document.querySelector(".site_header_module__button_to_search")

  @property "button_to_search",
    get: -> @element.querySelector(".quick_search_module__button_to_search")

  @property "search_form_input",
    get: -> @element.querySelector(".quick_search_module__search_form__input")

  on_init: ->
    @$element.on "click.#{@options.name}", ".quick_search_module__bar", (e) =>
      e.preventDefault()
      @element.toggleAttribute "aria-expanded"
      event.target.blur()
      @focus_search_form_input()

    @$element.on "click.#{@options.name}", ".quick_search_module__button_to_search", (e) =>
      e.preventDefault()
      @element.setAttribute "aria-expanded", ""
      event.target.blur()
      @focus_search_form_input()

    @$element.on "click.#{@options.name}", ".quick_search_module__button_to_close", (e) =>
      e.preventDefault()
      @element.removeAttribute "aria-expanded"

    @keyup_handler = (e) => @element.removeAttribute "aria-expanded" if e.key == "Escape"
    document.addEventListener "keyup", @keyup_handler

    if @button_to_search && @site_header_module_search_button
      setTimeout (=>
        options = { rootMargin: "0px", threshold: 0 }
        @observer = new IntersectionObserver(@on_observer.bind(@), options)
        @observer.observe(@site_header_module_search_button)
      ), 500

  on_destroy: ->
    @observer.disconnect() if @observer
    document.removeEventListener "keyup", @keyup_handler if @keyup_handler
    @element.removeAttribute "aria-expanded"

  focus_search_form_input: ->
    return unless @element.hasAttribute("aria-expanded")
    @search_form_input.focus()

  on_observer: (changes, observer) ->
    for change in changes
      if change.intersectionRatio > 0
        unless @button_to_search.hasAttribute("hidden")
          animType = "out"
          @button_to_search.setAttribute("data-anim", animType)
          @$element.one "animationend", ".quick_search_module__button_to_search rect:nth-child(30)", (e) =>
            @button_to_search.removeAttribute("data-anim")
            @button_to_search.setAttribute("hidden", true)
      else
        animType = "in"
        @button_to_search.toggleAttribute("hidden", false)
        @button_to_search.setAttribute("data-anim", animType)
        @$element.one "animationend", ".quick_search_module__button_to_search rect:nth-child(30)", (e) =>
          @button_to_search.removeAttribute("data-anim")

Content.register()
