# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class ProjectList extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'ProjectListModule'
#
#   @selector: ".project_list_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# ProjectList.register()
