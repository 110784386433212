import { default as WebModule__Container } from 'models/modulor/web_module/container'
import ScrollIntoView from 'lib/modulor/scroll_into_view'

export class Content extends WebModule__Container
  @defaults =
    debug: false
    name: 'PageSectionModule__Content'

  @selector: ".page_section_module__content"

  on_init: ->
    @intersection_oberver = new IntersectionObserver(@handleInteresectionObserver.bind(this), { threshold: 0 })
    @intersection_oberver.observe(@element)

    @$element.on "click.#{@options.name}", ".page_section_module__link_to__page_section_list_module", (e) =>
      return unless dom_id = e.target.getAttribute("href")
      return unless $page_section = $(dom_id)
      e.preventDefault()
      ScrollIntoView.call $page_section, always: true

  on_destroy: ->
    @intersection_oberver?.disconnect()

  handleInteresectionObserver: (entries, observer) ->
    for entry in entries
      $("body").toggleClass("sans_minidedek", entry.intersectionRatio > 0)

Content.register()
