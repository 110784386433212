import { default as WebModule__Cards } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Cards extends WebModule__Cards
  @defaults =
    debug: false
    name: 'SpreadsheetModule__Cards'

  @selector: ".spreadsheet_module__cards"

  @property "path",
    get: -> @element.getAttribute("data-path")

  @property "next_page_link",
    get: -> @element.querySelector(".spreadsheet__link__next_page")

  on_init: ->
    @observer = @observe_mutations(@on_path_change.bind(@), @element, { childList: false, subtree: false, attributes: true })
    await @replace_content_from_path()

  on_destroy: ->
    @observer.disconnect() if @observer

  on_path_change: ->
    @replace_content_from_path()

  replace_content_from_path: ->
    @element.innerHTML = await @fetch_data(@path)
    @observe_next_page_link()

  observe_next_page_link: ->
    return unless @next_page_link
    await next_intersection(@next_page_link)
    @load_next_page()

  load_next_page: ->
    path = @next_page_link.getAttribute("href")
    next_page_content = await @fetch_data(path)
    @next_page_link.outerHTML = next_page_content
    @observe_next_page_link()

  fetch_data: (path) ->
    return unless path
    response = await fetch(path)
    html = await response.text()
    doc = new DOMParser().parseFromString(html, "text/html")
    element = doc.body
    return "" unless element
    element.innerHTML.trim()

  observe_mutations: (callback, target = @element, options = { childList: true, subtree: true }) ->
    observer = new MutationObserver((mutations) =>
      observer.disconnect()
      Promise.resolve().then(start)
      callback.call(@, mutations)
    )
    start = -> observer.observe(target, options) if target.isConnected
    start()
    observer


Cards.register()

next_intersection = (element) ->
  new Promise (resolve) ->
    new IntersectionObserver( ([ entry ], observer) ->
      return if (!entry.isIntersecting)
      observer.disconnect()
      resolve()
    ).observe(element)
