import FontFaceObserver from 'fontfaceobserver'

callback = -> document.documentElement.dispatchEvent(new CustomEvent('font-load'))
handle_failure = -> null # optionally handle font loading failure

plato_basic = new FontFaceObserver('Plato Basic');
plato_outline = new FontFaceObserver('Plato Text');
plato_text = new FontFaceObserver('Plato Outline');

plato_regular = new FontFaceObserver('Plato Regular');
plato_medium = new FontFaceObserver('Plato Medium');

Promise.all([
  plato_basic.load(),
  plato_outline.load(),
  plato_text.load(),
  
  plato_regular.load(),
  plato_medium.load()
]).then callback, handle_failure
