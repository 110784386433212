import { Controller } from "@hotwired/stimulus"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller
  @targets: [ "svg" ]

  connect: ->
    @onStartListener = @onStart.bind(this)
    @element.addEventListener("graphic:start", @onStartListener)

    @onResetListener = @onReset.bind(this)
    @element.addEventListener("graphic:reset", @onResetListener)

  disconnect: ->
    @reset()
    @element.removeEventListener("graphic:start", @onStartListener)
    @element.removeEventListener("graphic:reset", @onResetListener)

  onStart: (event) ->
    event.stopImmediatePropagation()
    @start(event.detail)

  onCompleted: (event) ->
    return if event.currentTarget.isEqualNode(@element)
    return if @isCompleted

  onReset: (event) ->
    event.stopImmediatePropagation()
    @reset()


  totalBrickCount: ->
    @element.querySelectorAll("rect").length

  initialize: ->
    @brickCounter = 0

  start: (detail = {}) ->
    @isCompleted = false

    @animationType = detail.animationType || "ordered"

    switch @animationType
      when "ordered"
        @animationTypeAttribute = "ordered"
      when "shuffle"
        @animationTypeAttribute = "shuffle"
        @randomizeShuffle()


  randomizeShuffle: ->
    bricks = []
    for i in [0...@totalBrickCount()]
      bricks.push(i)

    for i in [bricks.length - 1..0]
      j = Math.floor(Math.random() * (i + 1))
      [bricks[i], bricks[j]] = [bricks[j], bricks[i]]

    @element.querySelectorAll("rect").forEach (brick) ->
      brick.style.animationDelay = "calc(#{bricks.pop()} * var(--animation-transition-step))"


  onAnimationEnd: (event) ->
    event.stopImmediatePropagation()

    @brickCounter += 1

    if @brickCounter == @totalBrickCount()
      @completed()

  completed: ->
    @element.dispatchEvent(@completedEvent)
    @isCompleted = true

  reset: ->
    @brickCounter = 0
    @animationTypeAttribute = null

  @property "animationTypeAttribute",
    set: (value) -> @svgTarget.setAttribute("data-animation-type", value)

  @property "completedEvent",
    get: -> new CustomEvent("graphic:completed", bubbles: true)
