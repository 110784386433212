import PreviewAttachments from '../__preview_attachments'

export class Cards extends PreviewAttachments
  @defaults =
    debug: false
    name: 'IdentityAssetsModule__Cards'
    front_class_name: 'identity_assets_module__preview_attachment__front'
    back_class_name: 'identity_assets_module__preview_attachment__back'
    current_class_name: 'identity_assets_module__preview_attachment__current'
    flipped_class_name: 'identity_assets_module__preview_attachments__flipped'

  @selector: ".identity_assets_module__preview_attachments[data-preview-option='cards']"

  on_init: ->
    super()
    @current_card_index = 0

    @add_side_classes()
    @set_current_class()
    @update_counter()

    @$element.on "click.#{@options.name}", (e) =>
      @$element.toggleClass(@options.flipped_class_name)
      setTimeout (=>
        @inc_current_card_index()
        @set_current_class()
        @update_counter()
      ), (666 * 0.6)


  on_destroy: ->
    super()
    @remove_side_classes()
    @remove_current_class()

  get_cards: -> @$element.find('.identity_assets_module__preview_attachment')
  get_current_cards: -> @get_cards().filter('.identity_assets_module__preview_attachment__current')

  inc_current_card_index: ->
    @current_card_index += 1
    @current_card_index = 0 if @current_card_index >= @get_cards().length

  add_side_classes: ->
    class_names = [@options.front_class_name, @options.back_class_name]
    @get_cards().each (i, el) => $(el).addClass( class_names[i % 2] )

  set_current_class: ->
    @remove_current_class()

    @get_cards().eq(@current_card_index).addClass(@options.current_class_name)

    next_card_index = @current_card_index + 1
    next_card_index = 0 if next_card_index >= @get_cards().length

    @get_cards().eq(next_card_index).addClass(@options.current_class_name)

  update_counter: ->
    index = @current_card_index + 1
    total = @get_cards().length

    $index = $("<span>#{index}</span>")
    $total = $("<span>#{total}</span>")

    @get_counter_index().attr('data-index', index).html($index)
    @get_counter_total().attr('data-total', total).html($total)

  remove_side_classes: ->
    @get_cards().removeClass(@options.front_class_name).removeClass(@options.back_class_name)

  remove_current_class: ->
    @get_cards().removeClass(@options.current_class_name)

Cards.register()
