import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class TickerItems extends Plugin
  @defaults =
    debug: false
    interval: 8000
    name: 'SiteHeaderModule__TickerItems'
    thumbnail_class_name: "site_header_module__ticker__item"
    current_thumbnail_class_name: "site_header_module__ticker__item__is_current"

  @selector: ".site_header_module__ticker__items"

  @property 'local_storage_identifier',
    get: -> @$element.data('cache-key')

  @property 'current_index_in_local_storage',
    get: -> parseInt(localStorage.getItem(@local_storage_identifier))
    set: (val) -> localStorage.setItem(@local_storage_identifier, val)

  @property "$thumbnails",
    get: -> @$element.find(".#{@options.thumbnail_class_name}")

  @property "current_index",
    get: -> @current_index_in_local_storage || @_current_index || 0
    set: (val) ->
      @_current_index = val
      @_current_index = 0 if @_current_index >= @$thumbnails.length
      @current_index_in_local_storage = @_current_index

  @property "max_height",
    get: -> Math.max (@$thumbnails.toArray().map((i) -> i.scrollHeight))...

  on_init: ->
    @set_height()
    @set_current_thumbnail()
    @thumbnail_interval = setInterval (=> @set_next_thumbnail()), @options.interval

  on_load: -> @set_height()
  on_ready: -> @set_height()
  on_turbolinks_render: -> @set_height()

  on_destroy: ->
    clearTimeout(@thumbnail_interval) if @thumbnail_interval
    @reset_height()

  set_next_thumbnail: ->
    @current_index += 1
    @set_current_thumbnail()

  set_current_thumbnail: ->
    @$thumbnails.removeClass(@options.current_thumbnail_class_name)
    @$thumbnails.eq(@current_index).addClass(@options.current_thumbnail_class_name)

  set_height: ->
    @$element.css("height", "#{@max_height}px")

  reset_height: ->
    @$element.css("height", null)

TickerItems.register()
