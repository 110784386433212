# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class Content extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'InterventionListHeaderModule__Content'
#
#   @selector: ".intervention_list_header_module__content"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# Content.register()
