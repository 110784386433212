import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'AnnouncementModule__Content'

  @selector: ".announcement_module__content"

  @property "body",
    get: -> @element.querySelector('.announcement_module__body')

  @property "type",
    get: -> @element.getAttribute("data-type")

  on_init: ->
    return unless @type == "new_building"

    @mouse_move_handler = @revealAnnouncementLayer.bind(@)
    @$element.on "mousemove", @mouse_move_handler

  on_destroy: ->
    @$element.off("mousemove", @mouse_move_handler) if @mouse_move_handler

  revealAnnouncementLayer: (event) ->
    x = event.clientX - @body.getBoundingClientRect().left
    @body.style.setProperty "--x-position",  "#{x}px"

Content.register()
