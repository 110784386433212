# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class InterventionListHeader extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'InterventionListHeaderModule'
#
#   @selector: ".intervention_list_header_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# InterventionListHeader.register()
