import Plugin from 'lib/modulor/plugin'

export class Note extends Plugin
  @defaults =
    debug: false
    name: 'FooterModule__Note'

  @selector: ".footer_module__note"

  on_init: ->
    @$element.on "click.#{@options.name}", (e) =>
      @scroll_to_top()

  scroll_to_top: ->
    $('html, body').animate(
      scrollTop: 0,
      250
    )

Note.register()
