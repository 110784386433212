# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class SharedFiles extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'SharedFilesModule'
#
#   @selector: ".shared_files_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# SharedFiles.register()
