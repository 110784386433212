import { default as WebModule__List } from 'models/modulor/web_module/list'
import ScrollIntoView from 'lib/modulor/scroll_into_view'

export class Content extends WebModule__List
  @defaults =
    debug: false
    name: 'PageSectionListModule__Content'

  @selector: ".page_section_list_module__content"

  on_init: ->
    @$element.on "click.#{@options.name}", ".page_section_list_module__page_section_module a", (e) =>
      return unless dom_id = e.target.getAttribute("href")
      return unless $page_section = $(dom_id)
      e.preventDefault()
      ScrollIntoView.call $page_section, always: true

Content.register()
