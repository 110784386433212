import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Index extends Plugin
  @selector: ".octopus_press_article_list_module__content" + "[data-view-option='index']"

  @property "rowsTarget",
    get: -> @element.querySelector(".octopus_press_article_list_module__rows")

  @property "rowTargets",
    get: -> @element.querySelectorAll(".octopus_press_article_page__row")

  @property "currentCol",
    get: -> @element.getAttribute("data-current-col")
    set: (col) ->
      @element.setAttribute("data-current-col", col)
      @currentColInDataStorage = col

  @property "currentDir",
    get: -> @element.getAttribute("data-current-dir")
    set: (dir) ->
      @element.setAttribute("data-current-dir", dir)
      @currentDirInDataStorage = dir

  @property "currentLanguage",
    get: -> @element.querySelector(".octopus_press_article_list_module__filter input[type='radio'][name='language']:checked")?.value || "all"
    set: (language) ->
      for radioButton in @element.querySelectorAll(".octopus_press_article_list_module__filter input[type='radio'][name='language']")
        radioButton.checked = radioButton.value == language
      @currentLanguageInDataStorage = language

  @property "currentArticleType",
    get: -> @element.querySelector(".octopus_press_article_list_module__filter input[type='radio'][name='article_type']:checked")?.value || "all"
    set: (articleType) ->
      for radioButton in @element.querySelectorAll(".octopus_press_article_list_module__filter input[type='radio'][name='article_type']")
        radioButton.checked = radioButton.value == articleType
      @currentArticleTypeInDataStorage = articleType

  @property 'localStorageIdentifier',
    get: -> 'octopus-press-article-list-index'

  @property 'currentColInDataStorage',
    get: -> localStorage.getItem([@localStorageIdentifier, "col"].join("--"))
    set: (val) -> localStorage.setItem([@localStorageIdentifier, "col"].join("--"), val)

  @property 'currentDirInDataStorage',
    get: -> localStorage.getItem([@localStorageIdentifier, "dir"].join("--"))
    set: (val) -> localStorage.setItem([@localStorageIdentifier, "dir"].join("--"), val)

  @property 'currentLanguageInDataStorage',
    get: -> localStorage.getItem([@localStorageIdentifier, "language"].join("--"))
    set: (val) -> localStorage.setItem([@localStorageIdentifier, "language"].join("--"), val)

  @property 'currentArticleTypeInDataStorage',
    get: -> localStorage.getItem([@localStorageIdentifier, "article-type"].join("--"))
    set: (val) -> localStorage.setItem([@localStorageIdentifier, "article-type"].join("--"), val)

  @property 'groupKeyTargets',
    get: -> @element.querySelectorAll(".octopus_press_article_list_module__index__group_key")

  @property 'groupKeyTemplateTarget',
    get: -> @element.querySelector(".octopus_press_article_list_module__index__group_key__template")

  on_init: ->
    @$element.on "click.#{@options.name}", ".octopus_press_article_list_module__index__button_to_close", (e) =>
      document.body.removeAttribute("data-octopus-index")

    @$element.on "click.#{@options.name}", ".octopus_press_article_list_module__head__button", (e) =>
      @toggleDir(e.currentTarget.getAttribute("data-col"))
      @sortRows()
      @updateGroupKeys()

    @$element.on "change.#{@options.name}", ".octopus_press_article_list_module__filter input[type='radio']", (e) =>
      switch e.currentTarget.name
        when "article_type"
          @currentArticleType = e.currentTarget.value
        when "language"
          @currentLanguage = e.currentTarget.value
      @filterRows()
      @updateGroupKeys()

    @currentCol = @currentColInDataStorage if @currentColInDataStorage
    @currentDir = @currentDirInDataStorage if @currentDirInDataStorage
    @currentLanguage = @currentLanguageInDataStorage if @currentLanguageInDataStorage
    @currentArticleType = @currentArticleTypeInDataStorage if @currentArticleTypeInDataStorage

    @sortRows()
    @filterRows()
    @updateGroupKeys()

  on_destroy: ->
    @removeGroupKeys()

  toggleDir: (col) ->
    if @currentCol == col
      @currentDir = (if @currentDir == "asc" then "desc" else "asc")
    else
      @currentCol = col

  sortRows: ->
    return if @_elementsAreSorted([...@rowTargets])
    if @currentDir == "asc"
      [...@rowTargets].sort(@_compareElements.bind(@)).forEach(@_append.bind(@))
    else
      [...@rowTargets].sort(@_compareElements.bind(@)).reverse().forEach(@_append.bind(@))

  filterRows: ->
    for rowTarget in @rowTargets
      languages = JSON.parse(rowTarget.getAttribute("data-languages"))
      isSelectedLanguage = @currentLanguage == "all" or @currentLanguage in languages

      articleType = rowTarget.getAttribute("data-article-type")
      isSelectedArticleType = @currentArticleType == "all" or @currentArticleType == articleType

      rowTarget.toggleAttribute "hidden", not (isSelectedLanguage and isSelectedArticleType)

  updateGroupKeys: ->
    @removeGroupKeys()
    @insertGroupKeys()

  removeGroupKeys: ->
    for groupKeyTarget in @groupKeyTargets
      groupKeyTarget.remove()

  insertGroupKeys: ->
    prevValue = null

    for rowTarget in @rowTargets
      unless rowTarget.hidden
        value = switch @currentCol
          when "title" then rowTarget.getAttribute("data-title")[0].toUpperCase() if rowTarget.getAttribute("data-title")
          when "author" then rowTarget.getAttribute("data-author")[0].toUpperCase() if rowTarget.getAttribute("data-author")
          when "related_pages" then rowTarget.getAttribute("data-related-pages")[0].toUpperCase() if rowTarget.getAttribute("data-related-pages")
          when "dposted" then rowTarget.getAttribute("data-dposted").split("-")[0] if rowTarget.getAttribute("data-dposted")
          else ""

        if prevValue == null || prevValue != value
          if value
            html = @groupKeyTemplateTarget.innerHTML.replaceAll("%{GROUP_KEY}", value)
            doc = new DOMParser().parseFromString(html, "text/html")
            newGroupKeyTarget = doc.body.firstChild
            rowTarget.parentNode.insertBefore(newGroupKeyTarget, rowTarget)

        prevValue = value

  _append: (child) ->
    @rowsTarget.append(child)

  _elementsAreSorted: ([left, ...rights]) ->
    for right in rights
      return false if @_compareElements(left, right) > 0
      left = right
    true

  _compareElements: (left, right) ->
    if (@_getSortCode(right) > @_getSortCode(left))
      return -1
    else
      return 1
    0

  _getSortCode: (el) ->
    el.getAttribute("data-#{@currentCol.replaceAll('_', '-')}") || ""

Index.register()
