import { default as WebModule__Content } from 'models/modulor/web_module/content'

export default class Content extends WebModule__Content
  @defaults =
    debug: false
    name: "VideoModule__Content"

  @selector: '.video_module__content'

  on_init: ->
    { default: videojs } = await `import('video.js' /* webpackChunkName: "videojs" */)`

    videojs(@get_video()[0])

  on_destroy: ->
    # this actually removes the <video> tag completely
    # so that it can't be reinitialised later
    # if @video
    #   @video.dispose()
    #   @video = undefined

  get_video: -> @$element.find("video")

Content.register()
