import Plugin from 'lib/modulor/plugin'

export default class Counter extends Plugin
  @defaults =
    debug: false
    name: 'ImagesModule__Counter'

  @selector: ".images_module__content"

  on_init: ->
    @$element.on "change.flickity", (e, index) =>
      @get_current_slide().text(index + 1)

    @$element.on "click.#{@options.name}", ".images_module__counter__arrow_prev", (e) =>
      @get_attachment_images().trigger("prevSlide")

    @$element.on "click.#{@options.name}", ".images_module__counter__arrow_next", (e) =>
      @get_attachment_images().trigger("nextSlide")

    @get_counter().css("cursor", "pointer")

  on_destroy: ->
    @get_counter().css("cursor", null)
    @$element.off ".flickity"

  get_counter: -> @$element.find(".images_module__counter")
  get_current_slide: -> @$element.find(".images_module__counter__current_slide")
  get_attachment_images: -> @$element.find(".images_module__attachment_images")

Counter.register()
