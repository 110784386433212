# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class ProjectListHeader extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'ProjectListHeaderModule'
#
#   @selector: ".project_list_header_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# ProjectListHeader.register()
