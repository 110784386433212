# import { default as WebModule__Content } from 'models/modulor/web_module/content'

# export class Content extends WebModule__Content
#   @defaults =
#     debug: true
#     name: 'ScreenModule__Content'

#   @selector: ".screen_module__content"

#   on_init: ->
#     console.log 'init' if @options.debug

# Content.register()
