import Page from 'models/modulor/page/page'

export class Norma extends Page
  @defaults =
    debug: false
    name: 'NormaPage'
    selectors: [
      '.norma_header_module__title',
      '.norma_header_module__date_range',
      '.norma_page__thumbnail__title',
      '.norma_page__thumbnail__date_range',
      '.norma_archive_module__list__item a',
      '.norma_page .expandable_text_module__title',
      '.norma_page .expandable_section_module__button'
    ]

  @selector: "body"

  on_init: ->
    for selector in @options.selectors
      @$element.find(selector).each (i, el) =>
        $(el).attr('data-content', $(el).text())

Norma.register()
