import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'WaqiModule__Content'

  @selector: ".waqi_module__content"

  @property "aqi",
    get: -> @$element.data("aqi")

  @property "aqiLevel",
    get: -> @$element.data("aqi-level")

  on_init: ->
    $("body").attr("data-aqi-level", @aqiLevel) if @aqiLevel

  on_destroy: ->
    $("body").attr("data-aqi-level", null)

Content.register()
