import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'ExpandableSectionModule__Content'
    expanded_class_name: 'expandable_section_module__content__is_expanded'

  @selector: ".expandable_section_module__content"

  @property "$button",
    get: -> @$element.find '.expandable_section_module__button'

  @property "$body",
    get: -> @$element.find '.expandable_section_module__body'

  @property "is_expanded",
    get: -> @$element.hasClass @options.expanded_class_name

  on_init: ->
    @$element.on "click.#{@options.name}", '.expandable_section_module__button', (e) =>
      @toggle_body()

    @$button.css 'cursor', 'pointer'

    @open() if @is_expanded

  toggle_body: ->
    return unless @$body

    if @is_expanded
      @close()
    else
      @open()

    setTimeout (->
      window.dispatchEvent(new Event('resize'));
      lazySizes.autoSizer.checkElems() if lazySizes
    ), 250

  open: ->
    @$body.css 'height', @$body[0].scrollHeight+"px"
    @$element.addClass @options.expanded_class_name

  close: ->
    @$body.css 'height', '0px'
    @$element.removeClass @options.expanded_class_name

Content.register()
