import _debounce from 'lodash/debounce'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class SignatureForm extends WebModule__Content
  @defaults =
    debug: false
    name: 'EmailSignatureModule__SignatureForm'
    debounce: 100

  @selector: ".email_signature_module__signature_form"

  on_init: ->
    @$element.on "keyup.#{@options.name}", 'input', _debounce ((e) =>
      @submit_form()
    ), @options.debounce

    @$element.on "change.#{@options.name}", 'input[type="radio"]', _debounce ((e) =>
      @submit_form()
    ), @options.debounce

  get_form: -> @$element.find('form')

  submit_form: -> @get_form().trigger('submit.rails')


SignatureForm.register()
