import _debounce from 'lodash/debounce'
import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Carousel extends Plugin
  @defaults =
    name: 'EventListModule__ViewOption__Carousel'
    cellSelector: ".event_list_module__section__day, .event_list_module__link_to__more"
    containerSelector: ".event_list_module__event_pages"
    currentThumbnailSelector: ".event_page__thumbnail__is_current"

  @selector: ".event_list_module__content" + "[data-view-option='carousel']"

  @property "$arrow_prev",
    get: -> @$element.find ".event_list_module__nav__arrow_prev"

  @property "$arrow_next",
    get: -> @$element.find ".event_list_module__nav__arrow_next"

  on_init: ->
    { default: Flickity } = await `import('plugins/flickity' /* webpackChunkName: "flickity" */)`

    @flickity = new Flickity(@get_container()[0],
      adaptiveHeight: false
      cellAlign: 'left'
      cellSelector: @options.cellSelector
      groupCells: "100%"
      initialIndex: @get_initial_index()
      pageDots: true
      prevNextButtons: false
    )

    @$element.on "prevSlide.#{@options.name}", (e) =>
      @flickity.previous() if @flickity

    @$element.on "nextSlide.#{@options.name}", (e) =>
      @flickity.next() if @flickity

    @font_load_handler = _debounce(@resize_flickity.bind(this), @options.debounce)
    $('html').on "font-load.#{@options.name}", @font_load_handler

    @$element.on "click.#{@options.name}", ".event_list_module__nav__arrow_prev", (e) =>
      @$element.trigger("prevSlide")

    @$element.on "click.#{@options.name}", ".event_list_module__nav__arrow_next", (e) =>
      @$element.trigger("nextSlide")

    @$element.on "select.flickity", (e, index) => @set_nav_index(index)

  on_destroy: ->
    @flickity.destroy() if @flickity
    @$element.off '.flickity'
    $('html').off('font-load', @font_load_handler) if @font_load_handler

  on_load: -> @resize_flickity()
  on_ready: -> @resize_flickity()
  on_turbolinks_render: -> @resize_flickity()

  get_container: -> @$element.find(@options.containerSelector)
  get_sections: -> @$element.find(@options.cellSelector)

  get_initial_index: -> 0

  get_current_thumbnail_index: ->
    return unless $thumbnail = @get_current_thumbnail()
    return unless $section = $thumbnail.closest(@options.cellSelector)
    $section.index()

  get_current_date_index: ->
    return unless date_string = @get_current_date_string()
    return unless $section = @get_sections().filter("[data-group-key='#{date_string}']").first()
    $section.index()

  get_current_date_string: ->
    date = new Date()
    year = date.getFullYear()
    month = date.getMonth() + 1
    month = "0#{month}" if month < 10
    day = date.getDate()
    day = "0#{day}" if day < 10
    "#{year}-#{month}-#{day}"

  resize_flickity: ->
    return unless @flickity
    @flickity.resize()

  set_nav_index: (index) ->
    # return unless @flickity
    if @flickity.slides.length <= 1
      @$arrow_prev.addClass('event_list_module__nav__arrow_prev__inactive')
      @$arrow_next.addClass('event_list_module__nav__arrow_next__inactive')
    else
      @$arrow_prev.toggleClass('event_list_module__nav__arrow_prev__inactive', (index == 0))
      @$arrow_next.toggleClass('event_list_module__nav__arrow_next__inactive', (index >= @flickity.slides.length - 1))

Carousel.register()
