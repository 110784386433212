import Plugin from 'lib/modulor/plugin'

export class ButtonToIndex extends Plugin
  @defaults =
    debug: false
    name: 'SiteHeaderModule__ButtonTo__Index'

  @selector: ".site_header_module__button_to__index"

  on_init: ->
    @$element.on "click.#{@options.name}", (e) =>
      @toggle_menu()

  toggle_menu: ->
    document.body.toggleAttribute("data-octopus-index")

ButtonToIndex.register()
